<!-- 助手跳转窍门图 -->
<template>
    <div class="doohickey flex-column">
        <div class="title">窍门图库</div>
        <div class="search">
            <el-input v-model="input" placeholder="请输入汉字进行搜索" suffix-icon="el-icon-search" maxlength="1"
                @input="onInput"></el-input>
        </div>
        <span class="result" v-if="input">共搜索到{{ count }}个结果</span>
        <div class="content flex-align-around" :class="{ 'only': wordList.length == 1 }">
            <div class="item flex-column-between" v-for="(item, index) in wordList.slice(0, 8)" :key="index">
                <img :src="defaultUrl + item + '.jpg'" alt="" @click="preview(item)" id="testImg">
                <div class="bottom flex-align-between">
                    <span class="word">{{ item }}</span>
                    <div class="menu flex-align">
                        <!-- <div class="download" @click="download(item)">
                            <i class="el-icon-upload"></i>
                            下载
                        </div> -->
                        <div class="copy" @click="copy(item)">
                            <i class="el-icon-copy-document"></i>
                            复制
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
export default {
    data() {
        return {
            count: 0,
            defaultUrl: 'https://yun.cnuzi.com/static/qmjpg/',
            input: "",
            // words: `一二三上口目耳手日田禾火虫云山八十了子人大月儿头里可东西天四是女开水去来不小少牛果鸟早书刀尺本木林土力
            // 心中五立正在后我好长比巴把下个雨们问有半从你才明同学自己衣白的又和竹牙马用几只石出见对妈全回工厂匆暗沟殷窥两哪宽
            // 顶眼睛肚皮孩跳变极片傍海洋作坏给带法如脚它娃她毛更知识处园桥群队旗铜号领巾杨壮桐枫松柏棉杉化桂歌写丛深六熊猫九朋友
            // 季吹肥农事忙归戴辛苦称柱底杆秤做岁站船然画幅评奖纸报另及拿并封信今支圆珠笔灯电影哄先闭脸沉发窗楼依尽黄层照炉烟挂川南
            // 部些巨位每升闪狗湾名胜迹央丽展现披份坡枝起客老收城市利井观沿答渴喝话际面阵朗枯却将纷夜棵谢想盯言邻治怪洪灾难道年被业
            // 产扁担志伍师军战士忘泼度龙炮穿向令危敢惊阴似野苍茫于论岸屋切久散步唱赶旺旁浑候谁轻汽食物爷就爪神活猪奶始吵急咬第公折
            // 张祝扎抓但哭车得秧苗汗场伤路晨绒球汉艳服装扮静停孔雀粗落荒笛舞狂罚假互所够猜扬臂断楚至孤帆饮初镜未磨遥银盘优淡浅错岩虾挺刺鼓数厚
            // 宝贵滨灰飘渔遍躺载靠亚夏除踩洁脑袋严实挡视线坛显材软刮库妙演奏琴感受激击器滴敲鸣诉读栽梨柔朝雾蒙鼻总抖露湿吸猎翅膀重司庭登跌众弃
            // 持掌班默离投刚调摇晃烈勇郊养粉谷粒男或者冻冷惜肯诚潮据堤阔盼滚顿逐渐堵犹崩震霎余淘牵鹅卵坑洼填庄稼俗跃葡萄稻熟豌按舒适恐僵硬枪耐
            // 探愉曾蚊即科横竖绳系蝇证研究达驾驶唤纪技改程超亿核奥益联质哲任善暮吟题侧峰庐缘降费须逊输虎操占嫩顺均叠隙茎柄萎瞧固宅临慎选择址良
            // 穴厅卧专卫较睁翻斧劈缓浊丈撑竭累血液奔茂滋帝曰溺返衔悲惨兽佩坚违抗环锁既狠著愤获嗅呆奈巢齿躯掩护幼搏庞量愣级链颤攀猴鲫念辫呵摸甚
            // 跪捶绕顽脖脱概惹昏握摔凭掐段俩练套裤逃罩亏挖撤堂砸锅否旋况兵败椅仍尤恨帅预溃品丑豪塞秦征词催醉杰亦雄项肃晰振胸怀赞效凡顾训斥戎尝
            // 诸竞唯豹派娶媳妇淹逼浮旱徒扔饶骗灌溉宜鹤嫌朱嵌框匣哨恩韵亩播浇吩咐亭榨慕矮懂兰箩婆糕饼浸缠茶捡汛访鞋挽隔懒惰稳衡协召臣议缺宫献诺
            // 典抄罪怯拒荆冠俯喷枚箭筒束赤圈置侵略筑堡党丘妨蔽陷拐酬珍叮嘱塌焦誓谎延悔扶郎爹嫂辆歹罕纱妻趟托狱婚辈挨祭乃熏杭亥恃哀拘泻潜试胎皇
            // 履疆毁估拱辉煌殿陵览境宏唐闯统销奉摄氏殖粮炭区杀菌疗鼠秀玲珑歇窝滑拾狭勉梳辞抑碌吊酷暑噪脊竟龟哇忍械酸权蚕考疼席糖屑启迪钉陪毕煮
            // 枕孙泊愁寺榆畔黎晕漆幕愈旷怡逸免桨榕纠耀桩涨塔梢暇眉抛耻诲谓诵岂舅津斩限凯葛述贾衰刊琐朴某毯陈裳虹蹄腐稍微缀幽雅案拙薄糊蕾襟恍怨
            // 德鹊蝉律崖渡索寇副榴弹抡贯棋悬沸涧雹屹悦屈政府宾栏汇爆宣帜阅制坦距隆射凛疙瘩棍裁筹橡雕跺颓沮丧趴屉谜尚氧倾揭斑燥漠磁抵御素盗培咆
            // 哮嗓哑揪呻废汹涌澎湃熄掀困唉淋嘿糟嘛皱勺棚苔藓坪蔗瀑增缝谚袖篷缩疯瓦柜喧甩嚷酱唇蹦涯莺莹裹篮蔼资矿慷慨贡滥基睹哉巍弦轴锦曝矣谱莱
            // 茵盲纯键缕陶郑拜租厨毡羞撒缚猬伶俐窜搁综澄萍藻漾削瞬凝骤掷陡春冬风雪花飞入姓什么双国王方青清气晴情请生字左右红时动万吃叫主江住没
            // 以会走北京广过各种样伙伴这太阳校金秋因为他地河说也听哥单居招呼快乐玩很当音讲行许思床前光低故乡色外看爸晚笑再午节叶米真分豆那着到
            // 高兴千成间迷造运池欢网古凉细夕李语香打拍跑足声身体之相近习远玉义首采无树爱尖角亮机台放鱼朵美直呀边呢吗吧加文次找平办让包钟元共已
            // 经坐要连百还舌点块非常往瓜进空病医别干奇七星吓怕跟家羊象都捉条爬姐您草房诗童碧妆绿丝剪冲寻姑娘吐柳荡桃杏鲜邮递员原叔局堆礼邓植格
            // 引注满休息锋昨冒留弯背洒温暖能桌味买具甘甜菜劳匹妹波纹像景恋舍求州华岛峡族谊齐奋贴街舟艾敬转团热闹贝壳甲骨钱币与财烧茄烤鸭肉鸡蛋
            // 炒饭彩梦森拉结苹般精灵伞姨弟便教游戏母周围句补充药合死记屁股尿净屎幸使劲亡牢钻劝丢告筋疲图课摆座室交哈页抢嘻愿意麦该伯刻突掉湖莲
            // 穷荷绝含岭吴雷乌黑压垂户新迎扑指针帮助导永碰特积杯失洗澡容易浴扇慢遇兔安根痛最店决定商夫终完换期蛙卖搬倒籽泉破应整抽纺织编怎布消
            // 祖啊浓望蓝摘掏赛忆世界功复式简弄由觉值类艰弓炎害此融燕鸳鸯惠崇芦芽梅溪泛减凑拂集聚形掠偶尔沾倦闲纤痕瓣蓬胀裂姿势仿佛随蹈止守株待
            // 宋耕触颈释其骄傲谦虚懦弱提尘捧代价鹿塘映欣赏匀致配传哎狮叹符欲魂借酒何牧兄独异佳伟录保存约验阿欧洲社赵省县匠设计史创举且智慧历芬
            // 芳内醒寿苏强示昆修建组蜜蜂辨阻跨括检查确误途陌秘密栋乘绪越状狐狸零巧克肠继续抬烦墨染竿腾碎拨浪葫爽蘑菇表胆鬼理夺骂仇差付倍虽泡件
            // 皂廊剩碗悠若透娇扯仰串婴希呈幻诱润芒冰剑普通模型宁官汪参攻推迅速退煤铁必胡灿骑秒凶猛接庙威武镇性卷货夹夸务衬衫负责艺漏喂胖驴贼狼
            // 莫厉抱架胶粘偏杂稀篱蜻蜓蝶宿徐疏茅檐翁赖剥构饰蹲凤序例率觅耸踏倘绘谐寄眠慰藉卜锐滩帐烁蝙蝠霸鹰怒吼脂拭餐划晌辣渗挣番埋刷测详笨钝
            // 鸽毫末描隧态吨颅膨肢翼辟纳拥箱臭蔬碳钢隐健康胞疾防灶需繁漫灭藤萝膝涛躲瓶挤叉挥桦涂茸绣潇穗朦胧寂霞抹忧虑贪职屏蹭稿腔解闷蛇遭殃盆
            // 勃讨厌坝忠毒绩孵警戒歪咕汤掘伏啼吠促颇剧苟譬侍馆附脾敏捷昂供添扩范努刹烂替镶紫仅浙罗杜鹃窄郁肩臀移额陆乳笋端源晋炕铅迈呜栓胳膊劫
            // 绸扒敌尸慌芙蓉洛壶雁砚乾坤囊萤恭勤博贫焉逢卒伦腹剖窟窿混嘶维秩卑岗宰措遣践介绍妖矩乖撵烫溜丫拽福舔葵瘦罢硕允砌牌禁惩踪啸私颊拆昼
            // 耘桑晓蝴蚱嗡樱拔瞎铲锄割承拴瓢逛妒忌曹督委鲁遮寨擂呐插冈饥碟斤俺榜杖申兼勿拖悉坠膛截仞岳摩遗涕巫彭拟谋瑞损锻炼眷赴搞殊尊签革庆诊
            // 沃龄匪绷审剂施吭崭衷慈祥荣跤搂仗鞭欺挠扳腕剃腮疤监侄喉咙浆傅袱障芝圣犯馅轰堪诈傻捏怔矛盾誉吾赢拳擦策荐艘航肆帽桅撕逗唬钩扭咧舱鸥
            // 瞄尼斯艇纵艄翘垫帘姆祷雇簇哗码仪眺骏驰辽绵凳吆铛罐恢踢牲畜梁诣禽拇搔痒秽轧拧螺纽扣貌仓渺享庸憎蒜醋饺摊拌眨宵燃贩彼贺轿骆驼恰腊粥
            // 腻咽匙搅稠肿熬褐缸脏侯章泣盈脉栖鸦惧凄寞宴霉籍聊乏栅控贷剔毙袭覆藏挪徘徊蒸裸媚砖叨绊绞耽揉绽搓惶吻偎络锤凿焚稚避峻啪瞪僻瞅靴魔刑
            // 哼绑啃袍执彻迁泰迫批标牺炊葬援俱弗辩域惯圃盐溅蕊魏搜蚯蚓版阶脆拦玻璃恶怖蟋蟀购讶捞尾盏筷皎寒径斜霜赠刘盖菊残君橙送挑铺泥晶院墙印
            // 排列规则乱棕迟盒颜料票争仙闻勾紧洞油曲丰柴旧裙怜饿蜡烛伸忽板富颗旅咱偷救命拼扫胃管乎流泪算准备等暴睡壁砍蜘蛛漂撞饱晒搭亲沙啦响羽
            // 翠嘴悄吞哦捕蒲英盛耍喊欠钓而察拢趣喜追宇宙腰凌哩棒谈父仔蚁焰险腿桶梯淌术认取篇民门蚂麻轮笼铃关反多短斗丁寸村趁册婶惑聪沫筝戚押炸疑`,
            wordList: [],
            words: `一丈三上下不丑专丘业丛东两严丧个中丰临丽乃久之乎乏九书乱了争事二于亏云互五井亚些亥亦产亩亭亲人亿仍从仙令们任份伍众优伤估伶
                伸似但位余作你佩依侧侵俐俗信俩俯候倾假做停偷傍催僵儿先免党全八六兰关典养兽册写军农冠况冷冻准凛凝几凡凭凯出击刀切刊列刘则刚初利刮制
                刺削副劈力功勇勉勺勾匆化匣区十千升午半协南占卧卫印危即却卵厂厅厚厨去又及友反发取受变叠口句另只召叮可号司吊同名后君吞吟否吩吵吸吹呆
                呢呵呻命咆和咐咬咱哀品哄哇哉响哑哦哨哪哭哮哲唇唉唐唤唯唱啦善喊喜喝喧喷嗅嗓嗜嘛嘱嘴嘿器噪嚷四回园困固圆圈土在场址均坏坑坚坛坡坦坪城
                培基堂堡堤堵塌塔塞填境墙增壁士壮处备夏夜够大天央头奈奉奏奔奖套奥女奶好如妇妈妙妨妻始娃娶婆婚媳嫂嫌嫩子孔孙季孤学孩它宅宏宜宝实客宣
                宫宽宾寇富寒察寸对寺封射将小少尚尝尤就尺尽层屈屉屋屑展履山屹岁岂岩岸峰崖崩嵌巍川巢工巨己已巴巾市帅帆师帘帝带席幅幕年并幼幽庄庐库底
                府庞废度庭延开弃张弦弹归影征径律徒得微德心忍志忘忙念怀怜怡急怨怪怯恃恍恐恨恩恰悄悔悦悬悲惊惜惨惰想惹愁愈愉感愣愤慎慕慨慷懂懒戎我或
                战戴所扁手才扎扔托扫扬扮扶找技抄把抑抓抖抗折抛抡护报披担拐拒拘拙拜拢择拱拼拾拿持挂按挑挖挡挨振挺挽捕捡据捶掀掌掐排探掩掷握揪揭搁搏
                搭摄摇摔摸撑撒撞撤播操攀支收改政效救敢散数敲斑斗料斜斥斧斩断旁旅旋旗既日旧早旱旷旺明昏是显晒晕晨晰晶暇暑暗暮暴曝曰曲更曾月有朋服朗
                朝木未本术朱朴杀权杆杉材村束来杨杭杰松板极枕林枚果枝枪枫枯柄柏某柔柜柱柴栏核栽桂框案桐桥桨桩梢梨械梳棉棋棍棕棚棵椅楚楼概榕榨榴横橙
                橡欠次歇歌止正步歹残殖段殷殿毁每比毕毛毡毯氏氧水汇汉汗汛汹汽沉沙沟沮沸油治沿泊法泥泪泻泼洁洋洞津活洼派流浅浇浊浑浮海浸涌涧涨涯液淋
                淘淡深淹渐渔渡渴湃湾湿溃溉溜溢溺滋滑滚滥滨滴漂漆演漠漾潜潮澄澎激瀑灌火灯灰炉炭炮点烈烛烟焦焰然煌照煮熄熊熏熟燥爆爪父爷爹牙牛物牵犹
                狂狗狠狭猎猜猪猫猬献猴王环现玲玻珍珑珠班球琐琴璃瓦甚生用甩田电男画畔略疆疗疙疯疼瘩登白的皇皮皱益盏盒盖盗盘盛目盯盲直盼眉真眼睁睛睡
                睹瞧瞪瞬矣知短矮石矿砍研砸硬碌磁磨祝神票祭离禾秀科租秤秦秧称程稍稳稻稼穴究穿窗窜窝窥立竖站竞竟竭竹笔笛第等筑筒答筹简管箩箭篮篷粉粒
                粗粮糊糕糖糟系素索紧累纠级纪纯纱纷纸线练经绒绕给统绰绳综缀缓缕缘缚缝缠缩缺罕罚罩罪置羞群羽翅翠翻耀老考者而耍耐耳耻联肃肚肥肯胃胎胜
                胡胸脊脑脖脚脱脸腐膀臂臣自至舅舒舞船良艳苍苔苗苦英茂茎茫茵茶荆荒莱获莹莺菊菌萄萍萎落著葛葡蒙蒜蒲蔗蔼蔽蕾薄藓虎虫虹虾蚁蚂蚊蚕蛛蜘蜡
                蝇蝉血衔衡衣衰袋袖被裁装裙裤裳裹襟西见观规视览言誓训议论访证评识诉词试诚话诲诵诸诺读谁谈谎谓谚谜谢谱谷豌豪豹贡败质贯贵费贾资赞赠赤
                赶超趟趣趴跃跌距跪路跳跺踩蹄蹦躯躺软轻载较辆辈辉输辛辞辫达迈返违迟迪述迹送适逃选逊逐逸逼遍道遥邻郊郎郑部酬酱酷酸醉里重野量钉钓铜银
                铺链销锁锅错锦键镜长门闪闭问闯闻阅阔队阴阵际降限陡院除险陪陵陶陷隆隔隙难雀雄雅雕雨雹雾震霎霜露静靠鞋韵顶项顺须顽顾顿预领颓颗题颜颤
                飘食饮饱饶饼饿马驶驾骗骤鸟鸣鹅鹊鹤麻黄黎默鼓鼠鼻齿龙春冬风雪花飞入姓什么双国王方青清气晴情请生字左右红时动万吃叫主江住没以多会走北
                京广公太阳校金秋因为他地河说也听哥单种居招呼快乐玩很当音讲行许思床前光低故乡色外看爸晚笑再午节叶米分样豆那着到高兴千成间迷造运池欢
                网古凉细夕李语香打拍跑足声身体之相近习远玉义首采无树爱尖角亮机台放鱼朵美过这呀边吗吧加文平办让包伙伴钟丁元面车共坐要连百今还舌点块
                非常往片瓜进空病她医别干奇七星吓怕跟起家羊象都捉条向爬姐您草房诗童碧妆绿丝剪冲寻姑娘仔吐柳荡桃杏鲜邮递员原叔局堆认礼邓植格引注满休
                息锋昨冒留弯背洒温暖能桌味买具甘甜菜劳匹妹波纹像景恋舍求州华岛族谊齐奋各民贴街舟艾敬转团热闹贝壳甲骨钱币与财购烧茄烤鸭肉鸡蛋炒饭彩
                梦森拉结苹般精灵伞姨弟便教游戏母周围补充药合死记屁股净总幸婶使劲亡牢钻劝丢告筋疲图课摆座室交哈页抢嘻愿意麦该伯刻突掉湖莲穷荷绝含岭
                吴雷乌黑压垂户新迎扑指针帮助导永碰特积杯失洗澡容易浴桶扇慢遇兔安根痛最店决定商夫终完换期蛙卖搬倒籽泉破应整抽纺织编怎布消祖啊浓望蓝
                摘掏赛忆觉值类艰弓炎害此帝忽由理段通融燕鸳鸯惠崇芦芽梅溪泛减凑拂集聚形掠偶尔沾倦闲纤痕瓣蓬胀裂姿势仿佛随蹈止守株待宋耕触颈释其骄傲
                谦虚懦弱提尘讶捧代价鹿塘映欣赏匀致配传哎狮追叹符欲魂借酒何牧兄独异佳伟录保存约验捞阿欧洲社世界赵省县匠设计史创举且智慧历芬芳内醒寿
                苏强示昆修建组蜜蜂辨阻跨括检查确误途陌宇宙淌秘密栋梯铃乘绪篇越状狐狸腰零巧克肠继续抬烦墨染竿腾碎拨浪葫爽蘑菇表胆夺骂仇差付倍虽鬼泡
                件式皂剩碗悠若透娇扯仰串婴希呈幻诱润芒冰剑普模型宁官汪参攻推迅速退轮煤铁必胡灿骑秒腿凶猛接庙威武镇性卷货算夹夸务衬衫负责艺漏喂胖驴
                贼狼莫厉抱架胶粘偏杂稀蜻蜓蝶宿徐疏茅檐翁笼赖剥构饰蹲凤序投例率耸踏倘绘谐寄眠慰藉卜锐滩帐烁蝙蝠霸鹰怒吼脂拭餐划晌辣渗挣番埋刷测详笨
                钝鸽毫凌末描隧态吨颅膨肢翼辟纳拥箱臭蔬碳钢隐健康胞疾防灶需繁漫灭藤萝膝涛躲瓶挤叉挥桦涂茸绣潇穗朦胧寂霞抹忧虑贪职屏蹭稿腔解闷蛇遭殃
                盆勃讨厌坝忠毒绩孵警戒歪咕汤掘伏啼调促颇剧苟譬侍馆附脾敏捷昂供添扩范努刹烂替镶紫仅浙罗杜鹃窄郁肩臀移额陆乳笋端源囊萤恭勤博贫逢晋炕
                铅晃哩栓胳膊劫绸扒敌尸趁慌芙蓉洛壶雁射弹启荣爆炸伦腹剖窟窿混嘶维秩岗宰措遣践介绍妖矩乖撵烫丫拽福舔葵瘦棒罢硕允砌牌禁惩踪啸私颊拆昼
                耘桑晓蝴蚱嗡樱榆拔瞎铲锄割尾承拴瓢逛妒忌曹督委鲁遮疑惑寨擂呐插冈饥碟斤俺榜杖申兼勿拖悉坠膛截仞岳摩遗涕巫篱彭拟谋瑞损锻炼眷赴搞殊尊
                签革庆诊沃龄匪绷审剂施吭崭衷慈祥跤搂仗鞭欺挠扳腕剃腮疤监侄喉咙浆傅袱障芝圣犯馅轰堪诈傻捏怔矛盾誉吾赢拳擦策荐艘航肆帽桅撕逗唬钩扭咧
                舱鸥瞄尼斯艇纵艄翘垫姆祷雇簇哗码仪眺骏驰辽绵凳吆铛罐恢踢牲畜梁聪诣禽拇搔痒秽轧拧螺纽扣貌仓渺享庸憎醋饺摊拌筝眨宵燃戚贩彼贺轿骆驼腊
                粥腻咽匙搅稠肿熬褐缸脏筷枣御侯皎章泣盈脉栖鸦惧凄寞宴霉籍聊乏控贷剔毙抵袭覆藏挪徘徊蒸裸媚砖蚁叨绊绞耽揉绽沫搓惶吻偎络锤凿焚稚避峻啪
                僻瞅靴魔刑哼绑啃袍押执彻迁泰迫批标牺炊葬援俱弗辩域惯圃盐溅蕊搜蚯蚓版阶脆拦恶怖蟋蟀鳞鱿杳孟峦戈妥毽函韪廷轴魅魁魉氢氮凰卸罔氛匝闺乾坤账句峡`,
        }
    },
    methods: {
        onInput(val) {
            console.log(val);
            if (!val.trim()) {
                this.wordList = this.words.split("")
            } else {
                let index = this.wordList.indexOf(val);
                console.log(index);
                if (index >= 0) {
                    this.wordList = this.wordList[index];
                    this.count = 1;
                } else {
                    this.wordList = [];
                    this.count = 0
                }
            }
        },
        // 复制图片
        copy(item) {
            const src = this.defaultUrl + item + '.jpg';
            // const testImg = document.querySelector("#testImg");
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            // canvas.width = testImg.width;
            // canvas.height = testImg.height;
            canvas.width = "1920";
            canvas.height = "900";
            img.crossOrigin = "Anonymous";
            img.src = src;
            console.log(img.src)
            img.onload = () => {
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;
                canvas.width = imgWidth;
                canvas.height = imgHeight;
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.drawImage(img, 0, 0);
                // 将canvas转为blob
                canvas.toBlob(async blob => {
                    console.log(blob);
                    const data = [
                        new ClipboardItem({
                            [blob.type]: blob,
                        }),
                    ];
                    // https://w3c.github.io/clipboard-apis/#dom-clipboard-write
                    await navigator.clipboard.write(data)
                        .then(
                            () => {
                                this.$message({
                                    message: '复制成功,可直接粘贴到课件中',
                                    type: 'success'
                                });
                                console.log("复制成功,可直接粘贴到课件中");
                            },
                            () => {
                                console.error("复制失败");
                                this.$message.error('复制失败');
                            }
                        );
                });
            }
        },
        // 预览图片
        preview(item) {
            window.open(this.defaultUrl + item + '.jpg')
        }
    },
    mounted() {

        // let token = sessionStorage.getItem('token');
        // console.log('token--', token)
        // sessionStorage.token = token;
        // this.$Api.Home.getUserInfo()
        //     .then(res => {
        //         console.log(res);
        //         if (res.code !== 200) {
        //             this.$router.push('/logins')
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })
        this.wordList = this.words.split("")

    },
};
</script>
<style lang='less' scoped>
@import "./doohickey.less";
</style>